import React from 'react'
import styled from 'styled-components'
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'

import { Link } from 'i18n/Link'
import SvgClose from 'assets/icons/svgClose.svg'
import { Title } from 'components/ui-kit/title'
import { Container } from 'components/ui-kit/Container'
import { Page } from 'components/layout/Page'
import { Button } from 'components/ui-kit/Button'
import { breakpoints } from 'components/ui-kit/globalValue'
import { Helmet } from 'react-helmet'
import { useTranslations } from 'i18n/useTranslations'

const ContactFormElement = styled.form``

const inputLineHeight = `20`

const InputElement = (props) => `
    box-sizing:border-box;
    width: 100%;
    margin: 5px 0;
    padding:4px 6px;
    border: none;
    border-bottom: 2px solid #222;
    font-size: 16px;
    line-height: ${inputLineHeight}px;
    :focus {
        outline:none;
        background-color:#ddd;
    }
`

const NameInput = styled.input`
    ${InputElement}
    box-sizing:border-box;
`
const PhoneInput = styled.input`
    ${InputElement}
`
const EmailInput = styled.input`
    ${InputElement}
`
const MessageInput = styled.textarea`
    height: ${inputLineHeight * 5}px;
    ${InputElement}
`

const Modal = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 30px;
    height: 75vh;
    min-height: 500px;
`
const ButtonStyled = styled(Button)`
    min-width: 200px;
    display: block;
    margin: 5px auto;
    text-align: center;
    box-sizing: border-box;
`
const ButtonContainer = styled(Container)`
    display: flex;
    justify-content: flex-end;
`
const FormContainer = styled(Container)`
    display: flex;
    justify-content: center;
    max-width: 738px;
`
const CloseButton = styled(Link)`
    display: inline-block;
`
const SvgCloseStyled = styled(SvgClose)`
    height: 30px;
    width: 30px;
    fill: #333;
    background-color: white;
    border-radius: 50%;
    @media (min-width: ${breakpoints.tablet}) {
        height: 50px;
        width: 50px;
    }
`

const IndexPageContent = ({ modal }) => {
    const translations = useTranslations()
    return (
        <>
            <Helmet>
                <title>
                    {translations.requestPrice} - {translations.logo} -
                    {translations.siteTitle}
                </title>
                <meta
                    name="description"
                    content="СПСК - Скала-подільський Спецкарʼєр.
                    "
                />
            </Helmet>
            <FormContainer>
                <ContactFormElement action="/">
                    <Title
                        as="h4"
                        view="underlined"
                        color={modal ? 'white' : 'black'}
                    >
                        {translations.writeToUs}
                    </Title>
                    <NameInput
                        aria-label="Введіть Ваше ім'я"
                        type="text"
                        name="name"
                        placeholder={translations.name}
                    />
                    <PhoneInput
                        aria-label="Введіть Номер телефону"
                        type="text"
                        name="phone"
                        placeholder={translations.phoneNumber}
                    />
                    <EmailInput
                        aria-label="Введіть вашу електронну адресу"
                        type="text"
                        name="email"
                        placeholder="Email"
                    />
                    <MessageInput
                        aria-label="Введіть повідомлення"
                        name="message"
                        placeholder={translations.message}
                    />
                    <ButtonStyled
                        aria-label="Відправити запит"
                        view={modal ? 'dark' : 'light'}
                        type="submit"
                    >
                        {translations.submit}
                    </ButtonStyled>
                </ContactFormElement>
            </FormContainer>
        </>
    )
}
const RequestPage = () => {
    const translations = useTranslations()
    return (
        <ModalRoutingContext>
            {({ modal, closeTo }) => (
                <>
                    {modal ? (
                        <Modal>
                            <ButtonContainer>
                                <CloseButton aria-label="Закрити" to={closeTo}>
                                    <SvgCloseStyled />
                                </CloseButton>
                            </ButtonContainer>
                            <IndexPageContent modal={modal} />

                            <FormContainer>
                                <ButtonStyled
                                    aria-label="Перейти на головну"
                                    view="dark"
                                    as={Link}
                                    to="/"
                                >
                                    {translations.index}
                                </ButtonStyled>
                            </FormContainer>
                        </Modal>
                    ) : (
                        <Page>
                            <IndexPageContent modal={modal} />
                        </Page>
                    )}
                </>
            )}
        </ModalRoutingContext>
    )
}

export default RequestPage
